<template>
  <travio-center-container grid-width='2/3'>

    <h2 style="color:#636363" class="mb-4">Membership Levels</h2>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="onAdd" type="filled">Add</vs-button>      
          <!-- <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          /> -->
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material mb-3"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="membershipLevels"
        :gridOptions="gridOptions"
        :rowDragManaged="true"
        :animateRows="true"
        domLayout="autoHeight"
        rowSelection="single"
        :pagination="false"
        :suppressPaginationPanel="true"
        :context="context"
        @first-data-rendered="onFirstDataRendered"
        @grid-ready="onGridReady"
      ></ag-grid-vue>

    </vx-card>

  </travio-center-container>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'

export default {
  components: {
    AgGridVue,
    CellRendererActions,
  },
  props: {
    applicationId: { required: true },
  },
  data () {
    return {
      membershipLevels: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions
      },
      context: null,
      showModal: false,
      isModalAdding: true,
      contentInEdit: {}
      // contentList: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  async created () {
    // this.membershipLevels = [
    //   {id: 1, name: 'Member 1', price: '329 GBP'},
    //   {id: 2, name: 'Member 2', price: '234 GBP'},
    //   {id: 3, name: 'Member 3', price: '2600 GBP'},
    //   {id: 4, name: 'Member 4', price: '120 GBP'},
    // ]
    this.$vs.loading()
    
    try {
      const response = await this.$http.get(`api/memberships/apps/${this.applicationId}`)
      this.membershipLevels = response.data.memberships
    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Id', rowDrag: true, field: 'id', width: 150, suppressSizeToFit: true },
      { headerName: 'Name', rowDrag: false, field: 'name', width: 250, suppressSizeToFit: false },
      { 
        headerName: 'Price',
        width:120,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueGetter: params => params.data.price + ' ' + params.data.currency
      },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  async mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.membershipLevels, x => x.id == rowNodeGettingDragged.data.id)
      const toIndex = e.overIndex
      array_move(this.membershipLevels, fromIndex, toIndex)
      
      try {
        let response = await this.$http.put(
          `api/memberships/apps/${this.applicationId}/order`,
          { membershipOrderItems: this.membershipLevels.map(m => ({ id: m.id, orderId: m.orderId }) ) })

        this.membershipLevels = [...this.membershipLevels]
        this.$_notifySuccess('Successfully reordered groups');
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      
    }
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAdd () {
      
      this.$router.push({name: 'application-tools-membership-levels-add', 
        params: { applicationId: this.applicationId }
      })
    },
    onEditMembership (data) {
      this.$router.push({name: 'application-tools-membership-levels-edit', 
        params: { applicationId: this.applicationId, membershipId: data.id }
      })
    },
    async onDeleteMembership (id) {

      this.$vs.loading()
      try {
          await this.$http.delete(`api/memberships/apps/${this.applicationId}/${id}`)
          this.membershipLevels = this.membershipLevels.filter(m => m.id != id)
          this.$_notifySuccess('Successfully deleted membership');
        } catch (error) {
          this.$_notifyFailure(error)
        }
        
      this.$vs.loading.close();

    },
  }
}
</script>

<style>

</style>
